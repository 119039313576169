<template>
  <div id="topAnchor" ref="faultTree">
    <backTop></backTop>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <navigation-component :id="1" />
      <div class="page-row page-header-row">
        <video id="bgVideo" class="bg" src="https://dating.517date.com/web/video.mp4" loop muted></video>
        <div class="content">
          <div class="title-item" data-aos="fade-up" data-aos-delay="100">
            <img src="../../assets/images/pc/pc-home-top-title.png" />
          </div>
          <div class="btn-item" data-aos="fade-up" data-aos-delay="200" data-aos-once="false">
            <a :href="upAppInfo.ios_down_url" target="_blank" rel="noopener noreferrer">
              <div class="btn">
                <img class="icon" src="../../assets/images/pc/ios-icon.png" />
                <span>App Store</span>
              </div>
            </a>
            <a :href="upAppInfo.android_down_url" target="_blank" rel="noopener noreferrer">
              <div class="btn">
                <img class="icon" src="../../assets/images/pc/android-icon.png" />
                <span>Android</span>
              </div>
            </a>
            <div class="btn pointer">
              <img class="icon" src="../../assets/images/pc/qrcode-icon.png" />
              <div class="qrcode-item" style="display: none;">
                <img :src="upAppInfo.qrcode" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-row page-home-item">
        <img class="shade " data-aos="fade-up" data-aos-delay="100" src="../../assets/oss/pc_index_shade_item.png" >
        <div class="content">
          <img class="detail-item-1 " data-aos="fade-up" data-aos-delay="200" src="../../assets/oss/pc_index_item_1.png" />
          <div class="title-item">
            <img data-aos="fade-up" data-aos-delay="200" src="../../assets/images/pc/pc-home-item-title-1.png" />
            <span data-aos="fade-up" data-aos-delay="300">网红 模特 主播 空乘</span>
            <span data-aos="fade-up" data-aos-delay="400">多重认证 真实可靠</span>
          </div>
        </div>
      </div>
      <div class="page-row page-home-item">
        <img class="shade" data-aos="fade-up" data-aos-delay="100" src="../../assets/oss/pc_index_shade_item.png" >
        <div class="content">
          <div class="flex"></div>
          <div class="title-item">
            <img data-aos="fade-up" data-aos-delay="200" src="../../assets/images/pc/pc-home-item-title-2.png" />
            <span data-aos="fade-up" data-aos-delay="300">分享爱情中的幸福</span>
            <span data-aos="fade-up" data-aos-delay="400">传递美好的情感体验</span>
          </div>
          <div class="flex"></div>
          <img class="detail-item-2" data-aos="fade-up" data-aos-delay="100" src="../../assets/oss/pc_index_item_2.png" />
        </div>
      </div>
      <div class="page-row page-home-item">
        <img class="shade" src="../../assets/oss/pc_index_shade_item.png" >
        <div class="content">
          <img class="detail-item-3" data-aos="fade-up" data-aos-delay="100" src="../../assets/oss/pc_index_item_3.png" />
          <div class="flex"></div>
          <div class="title-item">
            <img data-aos="fade-up" data-aos-delay="200" src="../../assets/images/pc/pc-home-item-title-3.png" />
            <span data-aos="fade-up" data-aos-delay="300">勇敢地大声表达爱</span>
            <span data-aos="fade-up" data-aos-delay="400">让整个夜空更加美丽</span>
          </div>
          <div class="flex"></div>
        </div>
      </div>
      <footer-component />
    </div>
  </div>
</template>

<script>

import NavigationComponent from '../../components/NavigationComponent.vue'
import FooterComponent from '../../components/FooterComponent.vue'
import Loading from '../../components/Loading.vue'
import { checkImg } from '../../utils/checkImg'
import backTop from "../../components/BackTop.vue"
import { getSetting } from '@/api/index'
//import { WOW } from 'wowjs'

//import { inject } from 'vue'
//const AOS = inject('AOS')
//import axios from 'axios'
//import { onMounted } from "vue";
//import AOS from "aos"
//onMounted(() => {
//    AOS.init();
//})

export default {
  name: 'Home',
  metaInfo: {
    title: '官网 - 偶遇圈',
    meta: [
      {
        name: 'description',
        content: '偶遇圈app，是一个为单身男女提供，附近高颜值、高素质的真人交友平台！'
      },
      {
        name: 'keywords',
        content: '偶遇、艳遇、邂逅、偶遇圈、偶遇圈app、偶遇圈下载、同城交友、附近约会'
      }
  ]
  },
  components: {
    NavigationComponent,
    FooterComponent,
    Loading,
    backTop
  },
  data () {
    return {
      pageLoading: true,
      upAppInfo: {}
    }
  },
  mounted () {
    this.handleGetSetting()
    checkImg(() => {
      document.getElementById('bgVideo').onplay = () => {
        this.pageLoading = false
      }
      document.getElementById('bgVideo').play()
    })
    //this.$nextTick( () => {
    //  new WOW().init()
    //})
    this.$nextTick( () => {
        setTimeout(function() { 
          var myEvt = new Event('resize')
          window.dispatchEvent(myEvt)
          //AOS.kill() ;
          //AOS.refresh(); 
        }, 500);
    })
    //document.addEventListener("DOMContentLoaded", function() {
    //    setTimeout(function() { 
    //      var myEvt = new Event('resize')
    //      window.dispatchEvent(myEvt)
    //      //AOS.kill() ;
    //      //AOS.refresh(); 
    //      console.log('hey')
    //      alert('oh')
    //    }, 500);
    //});
  },
  methods: {
    handleGetSetting () {
      getSetting({key: 'up_app'}).then(res => {
        try {
          this.upAppInfo = JSON.parse(res.info)
        } catch (error) {
          this.upAppInfo = res.info
        }
        console.log(this.upAppInfo);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  flex: 1;
}
.page-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .content {
    position: relative;
    z-index: 1;
  }
}

#backTop {
  position: fixed;
  right: 45px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  z-index: 99999;
  color: white;
  background: #fade3e;
  border-radius: 3px;
}
#backTop:hover {
  background-color: gray;
  transition: 500ms;
}

.page-header-row {
  //height: 1000px;
  min-height: 100vh;
  .bg {
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
  }
  .title-item {
    width: 596px;
    img {
      width: 100%;
      display: block;
    }
  }
  .btn-item {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    .btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: rgba(255, 255, 255, 0.15);
      height: 45px;
      padding: 0 10px;
      border-radius: 6px;
      font-size: 17px;
      margin: 0 10px;
      transition: all 0.5s;
      cursor: pointer;
      &.pointer {
        cursor: pointer;
      }
      &:hover {
        border: 1px solid rgba(108, 117, 125, 0.65);
        background: rgba(108, 117, 125, 0.65);
        .qrcode-item {
          display: block !important;
          transition: all 0.5s;
        }
      }
      .icon {
        width: 26px;
        height: 26px;
      }
      span {
        margin-left: 10px;
        color: #fff;
      }
      .qrcode-item {
        position: absolute;
        top: 60px;
        left: -1px;
        width: 230px;
        height: 230px;
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
}
.page-home-item {
  min-height: calc(100vh - 100px);
  position: relative;
  .shade {
    height: 299px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .content {
    /*width: 100%;*/
    min-width: 1450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    .title-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      img {
        width: 270px;
        height: 78px;
        margin-bottom: 30px;
      }
      span {
        font-size: 21px;
        color: #acacac;
        text-align: right;
        line-height: 39px;
        padding-right: 20px;
      }
    }
    .detail-item-1 {
      width: 1068px;
      height: 909px;
      margin: 100px 16px 0 0;
    }
    .detail-item-2 {
      width: 977px;
      height: 965px;
      margin: 50px 20px 0 0;
    }
    .detail-item-3 {
      width: 816px;
      height: 749px;
      margin: 227px 0 80px 0px;
    }
  }
}
</style>
