var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"faultTree",attrs:{"id":"topAnchor"}},[_c('backTop'),(_vm.pageLoading)?_c('loading'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pageLoading),expression:"!pageLoading"}],staticClass:"container"},[_c('navigation-component',{attrs:{"id":1}}),_c('div',{staticClass:"page-row page-header-row"},[_c('video',{staticClass:"bg",attrs:{"id":"bgVideo","src":"https://dating.517date.com/web/video.mp4","loop":"","muted":""},domProps:{"muted":true}}),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"btn-item",attrs:{"data-aos":"fade-up","data-aos-delay":"200","data-aos-once":"false"}},[_c('a',{attrs:{"href":_vm.upAppInfo.ios_down_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._m(1)]),_c('a',{attrs:{"href":_vm.upAppInfo.android_down_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._m(2)]),_c('div',{staticClass:"btn pointer"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/pc/qrcode-icon.png")}}),_c('div',{staticClass:"qrcode-item",staticStyle:{"display":"none"}},[_c('img',{attrs:{"src":_vm.upAppInfo.qrcode}})])])])])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('footer-component')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-item",attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('img',{attrs:{"src":require("../../assets/images/pc/pc-home-top-title.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/pc/ios-icon.png")}}),_c('span',[_vm._v("App Store")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/pc/android-icon.png")}}),_c('span',[_vm._v("Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-row page-home-item"},[_c('img',{staticClass:"shade",attrs:{"data-aos":"fade-up","data-aos-delay":"100","src":require("../../assets/oss/pc_index_shade_item.png")}}),_c('div',{staticClass:"content"},[_c('img',{staticClass:"detail-item-1",attrs:{"data-aos":"fade-up","data-aos-delay":"200","src":require("../../assets/oss/pc_index_item_1.png")}}),_c('div',{staticClass:"title-item"},[_c('img',{attrs:{"data-aos":"fade-up","data-aos-delay":"200","src":require("../../assets/images/pc/pc-home-item-title-1.png")}}),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_vm._v("网红 模特 主播 空乘")]),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"400"}},[_vm._v("多重认证 真实可靠")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-row page-home-item"},[_c('img',{staticClass:"shade",attrs:{"data-aos":"fade-up","data-aos-delay":"100","src":require("../../assets/oss/pc_index_shade_item.png")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"flex"}),_c('div',{staticClass:"title-item"},[_c('img',{attrs:{"data-aos":"fade-up","data-aos-delay":"200","src":require("../../assets/images/pc/pc-home-item-title-2.png")}}),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_vm._v("分享爱情中的幸福")]),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"400"}},[_vm._v("传递美好的情感体验")])]),_c('div',{staticClass:"flex"}),_c('img',{staticClass:"detail-item-2",attrs:{"data-aos":"fade-up","data-aos-delay":"100","src":require("../../assets/oss/pc_index_item_2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-row page-home-item"},[_c('img',{staticClass:"shade",attrs:{"src":require("../../assets/oss/pc_index_shade_item.png")}}),_c('div',{staticClass:"content"},[_c('img',{staticClass:"detail-item-3",attrs:{"data-aos":"fade-up","data-aos-delay":"100","src":require("../../assets/oss/pc_index_item_3.png")}}),_c('div',{staticClass:"flex"}),_c('div',{staticClass:"title-item"},[_c('img',{attrs:{"data-aos":"fade-up","data-aos-delay":"200","src":require("../../assets/images/pc/pc-home-item-title-3.png")}}),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_vm._v("勇敢地大声表达爱")]),_c('span',{attrs:{"data-aos":"fade-up","data-aos-delay":"400"}},[_vm._v("让整个夜空更加美丽")])]),_c('div',{staticClass:"flex"})])])
}]

export { render, staticRenderFns }