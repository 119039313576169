<template>
    <div id="goTop" v-show="goTopShow">
      <a @click="goTop" class="toTop"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 13 13">
  <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/>
</svg></a>
    </div>
  </template>
  
  <script>
    export default {
      name: "backTop",
      data() {
        return {
          scrollTop: '',
          goTopShow: false,
        }
      },
      created() {
      },
      methods: {
        handleScroll() {
          this.scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
          if (this.scrollTop > 150) {//设置页面滑动多少显示按钮
            this.goTopShow = true
          } else {
            this.goTopShow = false;
          }
        },
        goTop() {
          let timer = null,
            _that = this;
          cancelAnimationFrame(timer)
          timer = requestAnimationFrame(function fn() {
            if (_that.scrollTop > 0) {
              _that.scrollTop -= 50;//回到顶部的速度
              document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
              timer = requestAnimationFrame(fn)
            } else {
              cancelAnimationFrame(timer);
              _that.goTopShow = false;
            }
          })
        }
      },
      mounted() {
        window.addEventListener('scroll', this.handleScroll);
      },
      destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
      }
    }
  </script>
  
  <style scoped>
    .goTop:hover {
        background-color: gray;
        transition: 500ms;
    }
  
    .toTop {
        position: fixed;
        right: 45px;
        bottom: 60px;
        width: 40px;
        height: 40px;
        z-index: 99999;
        color: white;
        background: #fade3e;
        border-radius: 3px;
    }
  </style>
  
  